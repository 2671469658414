<template>
  <b-overlay :show="loading">
    <b-card v-if="report !== null">
      <equity-chart
        v-if="dayDetail !== null"
        :equities="dayDetail.equity"
        :open-balance="dayDetail.equity_report ? dayDetail.equity_report.open_balance : null"
        :title="`Equity chart for trading day ${dayDate}`"
        @close-chart="onCloseMainChart"
      />

      <!--      Equity chart for trade -->
      <equity-chart
        v-if="tradeDetail.equity !== null"
        :equities="tradeDetail.equity"
        :title="tradeDetail.title"
        @close-chart="onCloseDayChart"
      />
      <trades-table
        v-if="dayDetail !== null"
        :trades="dayDetail.trades"
        :trades-report="dayDetail.trades_report"
        :day="dayDate"
        @trade-detail="onViewTradeDetail"
      />
      <hr>
      <days-table
        :days="report.days"
        @day-detail="onViewDayDetail"
        @download="onDownload"
      />
    </b-card>
    <b-card v-else>
      No report found
    </b-card>
  </b-overlay>
</template>
<script>
import { BOverlay, BCard } from 'bootstrap-vue'
import AccountAPI from '@/api/account'
import DaysTable from '@/views/tools/aventus-reports/components/DaysTable.vue'
import TradesTable from '@/views/tools/aventus-reports/components/TradesTable.vue'
import EquityChart from '@/views/tools/aventus-reports/components/EquityChart.vue'
import { formatDateTime, formatNumber } from '@/plugins/formaters'

export default {
  components: {
    EquityChart,
    TradesTable,
    DaysTable,
    BCard,
    BOverlay,
  },
  data() {
    return {
      loading: false,
      report: null,
      dayDetail: null,
      dayDate: null,
      tradeDetail: {
        detail: null, id: null, title: null, equity: null,
      },
      tradeDetailEquity: null,
    }
  },
  computed: {
    accountId() {
      return this.$route.params.id
    },
  },
  beforeMount() {
    this.loading = true
    AccountAPI.getAccountAventusReport(this.accountId).then(data => {
      this.report = data
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    formatDateTime,
    formatNumber,
    resetDayView() {
      this.tradeDetail = {
        detail: null, id: null, title: null, equity: null,
      }
    },
    onCloseMainChart() {
      this.resetDayView()
      this.dayDetail = null
      this.dayDate = null
    },
    onCloseDayChart() {
      this.resetDayView()
    },
    onViewDayDetail(day) {
      this.resetDayView()
      this.dayDetail = this.report.days[day]
      this.dayDate = day.substring(0, 10)
    },
    onViewTradeDetail(tradeId) {
      this.tradeDetail.equity = this.dayDetail.e_matrix[tradeId]
      this.tradeDetail.detail = this.dayDetail.trades_report[tradeId]
      this.tradeDetail.id = tradeId
      this.tradeDetail.title = `Equity chart for trade id: ${this.tradeDetail.id} | lowest equity: ${formatNumber(this.tradeDetail.detail.equity)} at ${formatDateTime(this.tradeDetail.detail.lowestAt).replace('UTC', '')}`
    },
    onDownload(day) {
      const report = this.report.days[day].csv_report

      const blob = new Blob([report], { type: 'text/csv;charset=utf-8;' })

      const link = document.createElement('a')

      const url = URL.createObjectURL(blob)

      link.setAttribute('href', url)
      link.setAttribute('download', `Report-${this.accountId}-${day.substring(0, 10)}`)

      link.style.visibility = 'hidden'

      document.body.appendChild(link)

      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    },
  },
}
</script>
