<template>
  <div>
    <h2 class="mb-1">
      Trades from {{ day }}
    </h2>
    <b-table
      striped
      hover
      responsive
      small
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by="'close_datetime'"
      :sort-desc="true"
    >
      <template #cell(type)="data">
        <div
          v-if="data.value === 0"
          class="color-info"
        >
          Buy
        </div>
        <div
          v-else-if="data.value === 1"
          class="color-warning"
        >
          Sell
        </div>
      </template>
      <template #cell(profit)="data">
        <div
          v-if="riskLimit !== 0 && data.value <= account.deposit * (riskLimit/100) * -1"
          class="color-warning"
        >
          {{ formatCurrency(data.value, currency) }}
        </div>
        <span v-else>
          {{ formatCurrency(data.value, currency) }}
        </span>
      </template>
      <template #cell(swap)="data">
        {{ formatCurrency(data.value, currency) }}
      </template>
      <template #cell(commission)="data">
        {{ formatCurrency(data.value, currency) }}
      </template>
      <template #cell(close_datetime)="data">
        {{ formatDateTime(data.value) }}
      </template>
      <template #cell(open_datetime)="data">
        {{ formatDateTime(data.value) }}
      </template>
      <template #cell(openTime)="data">
        {{ formatDateTime(data.value).replace("UTC", "") }}
      </template>
      <template #cell(closeTime)="data">
        {{ formatDateTime(data.value).replace("UTC", "") }}
      </template>

      <template #cell(aventus_le)="data">
        {{ tradesReportValue(data.item.trade_id, 'equity') }}
      </template>

      <template #cell(aventus_le_at)="data">
        {{ tradesReportValue(data.item.trade_id, 'lowestAt', 'date') }}
      </template>

      <template #cell(aventus_le_tick)="data">
        {{ tradesReportValue(data.item.trade_id, 'tick') }}
      </template>

      <template #cell(actions)="data">
        <b-button
          v-if="tradesReport[data.item.trade_id]"
          variant="outline-secondary"
          @click="onView(data.item.trade_id)"
        >
          <feather-icon icon="EyeIcon" />
        </b-button>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </div>
</template>
<script>
import {
  BButton, BCardBody, BFormGroup, BFormSelect, BPagination, BTable,
} from 'bootstrap-vue'
import { formatCurrency, formatDateTime, formatNumber } from '@/plugins/formaters'

export default {
  components: {
    BButton,
    BCardBody,
    BFormSelect,
    BFormGroup,
    BPagination,
    BTable,
  },
  props: {
    trades: Object,
    tradesReport: Object,
    day: String,
  },
  data() {
    return {
      riskLimit: 0,
      perPage: 10,
      pageOptions: [3, 5, 10, 25],
      currentPage: 1,
      filter: null,
      filterOn: [],
      currency: 'USD',
      fields: [
        {
          key: 'symbol', label: 'Symbol', sortable: true, width: 250,
        },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'openTime', label: 'Open date MT', sortable: true },
        { key: 'closeTime', label: 'Closed date MT', sortable: true },
        { key: 'open_datetime', label: 'Open date utc', sortable: true },
        { key: 'close_datetime', label: 'Closed date utc', sortable: true },
        { key: 'open_price', label: 'Open price', sortable: true },
        { key: 'close_price', label: 'Closed price', sortable: true },
        { key: 'volume', label: 'Lots', sortable: true },
        { key: 'take_profit', label: 'Take profit', sortable: true },
        { key: 'stop_loss', label: 'Stop loss', sortable: true },
        { key: 'swap', label: 'Swap', sortable: true },
        { key: 'commission', label: 'Commission', sortable: true },
        { key: 'profit', label: 'Profit', sortable: true },
        { key: 'aventus_le', label: 'Aventus LE', sortable: true },
        { key: 'aventus_le_at', label: 'Aventus LE at', sortable: true },
        { key: 'aventus_le_tick', label: 'Aventus LE Tick', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false },
      ],
    }
  },
  computed: {
    items() {
      return Object.values(this.trades)
    },
    totalRows() {
      return this.items.length
    },
  },
  methods: {
    formatNumber,
    formatDateTime,
    formatCurrency,
    onView(id) {
      this.$emit('trade-detail', id)
    },
    tradesReportValue(tradeId, key, type = null) {
      if (type === 'date') return this.tradesReport[tradeId] ? formatDateTime(this.tradesReport[tradeId][key]).replace('UTC', '') : '-'

      return this.tradesReport[tradeId] ? this.tradesReport[tradeId][key] : '-'
    },
  },
}
</script>
