<template>
  <div>
    <h2 class="mb-1">
      Trading days
    </h2>
    <b-table
      striped
      hover
      responsive
      small
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      :items="items"
    >
      <template #cell(date)="data">
        {{ data.item.date.substring(0,10) }}
      </template>

      <template #cell(lowest)="data">
        {{ formatNumber(data.item.lowest) }}
      </template>

      <template #cell(lowest_equity)="data">
        <span v-if="data.item.equity_report">
          {{ formatNumber(data.item.equity_report.open_balance + data.item.lowest) }}
        </span>
        <span v-else>
          -
        </span>
      </template>

      <template #cell(lowest_equity_internal)="data">
        <span v-if="data.item.equity_report">
          {{ formatNumber(data.item.equity_report.lowest) }}
        </span>
        <span v-else>
          -
        </span>
      </template>

      <template #cell(lowest_datetime)="data">
        {{ formatDateTime(data.item.lowest_datetime).replace("UTC", "") }}
      </template>

      <template #cell(lowest_datetime_internal)="data">
        <span v-if="data.item.equity_report">
          {{ formatDateTime(data.item.equity_report.lowest_datetime) }}
        </span>
        <span v-else>
          -
        </span>
      </template>

      <template #cell(open_balance_internal)="data">
        <span v-if="data.item.equity_report">
          {{ formatNumber(data.item.equity_report.open_balance) }}
        </span>
        <span v-else>
          -
        </span>
      </template>

      <template #cell(actions)="data">
        <b-button
          variant="outline-secondary"
          class="m-25"
          @click="onView(data.item.date)"
        >
          <feather-icon icon="EyeIcon" />
        </b-button>
        <b-button
          class="m-25"
          variant="outline-success"
          @click="onDownload(data.item.date)"
        >
          <feather-icon icon="ArrowDownIcon" />
        </b-button>
      </template>
    </b-table>
    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-body>
  </div>
</template>
<script>
import {
  BPagination, BTable, BButton, BFormGroup, BCardBody, BFormSelect,
} from 'bootstrap-vue'
import { formatDateTime, formatNumber } from '@/plugins/formaters'

export default {
  components: {
    BFormSelect, BCardBody, BFormGroup, BPagination, BTable, BButton,
  },
  props: {
    days: Object,
  },
  data() {
    return {
      ddLimit: 2.5,
      ddCap: 0,
      perPage: 10,
      pageOptions: [3, 5, 10],
      currentPage: 1,
      fields: [
        { key: 'date', label: 'Day', sortable: true },
        { key: 'lowest', label: 'Max loss', sortable: true },
        { key: 'lowest_equity', label: 'Lowest equity', sortable: true },
        { key: 'lowest_equity_internal', label: 'Lowest equity internal', sortable: true },
        { key: 'lowest_datetime', label: 'LE time', sortable: true },
        { key: 'lowest_datetime_internal', label: 'LE time internal', sortable: true },
        { key: 'open_balance_internal', label: 'Open balance', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false },
      ],
      equityEdit: null,
    }
  },
  computed: {
    items() {
      return Object.entries(this.days).map(([date, data]) => ({
        date, lowest: data.lowest.equity, lowest_datetime: data.lowest.lowestAt, equity_report: data.equity_report,
      }))
    },
    totalRows() {
      return this.items.length
    },
  },
  methods: {
    formatNumber,
    formatDateTime,
    onView(day) {
      this.$emit('day-detail', day)
    },
    onDownload(day) {
      this.$emit('download', day)
    },
  },
}
</script>
