<template>
  <div>
    <div class="d-flex justify-content-between">
      <h2 class="my-auto">
        {{ title }}
      </h2>
      <b-form-row>
        <b-form-checkbox
          v-if="openBalance"
          v-model="addOpenBalance"
          class="my-auto"
        >
          Add open balance
        </b-form-checkbox>
        <b-button
          variant="outline-danger"
          size="sm"
          class="m-1"
          @click="$emit('close-chart')"
        >
          x
        </b-button>
      </b-form-row>
    </div>
    <vue-apex-charts
      type="line"
      height="300px"
      class="balance-chart"
      :options="equityChart.chartOptions"
      :series="equityChart.series"
    />
  </div>
</template>
<script>
import { BFormRow, BFormCheckbox, BButton } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { formatDateTime, formatNumber } from '@/plugins/formaters'

export default {
  components: {
    VueApexCharts, BFormRow, BFormCheckbox, BButton,
  },
  props: {
    equities: Object,
    openBalance: [Number, null],
    title: String,
  },
  data() {
    return {
      addOpenBalance: false,
    }
  },
  computed: {
    chartData() {
      return Object.entries(this.equities).map(([time, data]) => ({
        date: formatDateTime(time),
        min: this.addOpenBalance ? data.min + this.openBalance : data.min,
        max: this.addOpenBalance ? data.max + this.openBalance : data.max,
        minTime: formatDateTime(data.min_time),
        maxTime: formatDateTime(data.max_time),
      }))
    },
    equityChart() {
      return {
        series: [
          {
            name: 'Max equity',
            data: this.chartData.map(e => e.max),
          },
          {
            name: 'Min equity',
            data: this.chartData.map(e => e.min),
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: true },
            type: 'line',
            dropShadow: {
              enabled: false,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10,
            zoom: {
              type: 'x',
              enabled: true,
              autoScaleYaxis: true,
            },
          },
          stroke: {
            curve: 'straight',
            width: 5,
          },
          grid: {
            borderColor: '#EBE9F1',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: [$themeColors.success, $themeColors.danger],
          markers: {
            size: 2,
            colors: 'grey',
            hover: {
              size: 6,
            },
          },
          xaxis: {
            labels: {
              show: false,
            },
            categories: this.chartData.map(e => e.date),
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter: val => formatNumber(val, 'k'),
            },
          },
          tooltip: {
            x: { show: true },
            y: {
              formatter: value => value,
            },
          },
        },
      }
    },
  },
}
</script>
